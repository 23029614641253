import React from "react";
import Card from "react-bootstrap/Card";

import ListGroup from "react-bootstrap/ListGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsHeartFill } from "react-icons/bs";

import { Currency, asset, route } from "./utils/utils";

import { VOTING, VERIFICATIONINPROGRESS } from "./utils/Status";

export default function ListItems({ projet, langues, lngCode }) {
  
  const STATUS = projet.code_statut;

  function t(translation) {
    return langues[lngCode][translation]
  }


  return (
    <Card className="position-relative custom-width">
      <Card.Img
        className="image-projet"
        variant="top"
        src={asset(projet.photo)}
      />
      <Card.ImgOverlay className="d-flex justify-content-between h-20">
        <Card.Text className="bg-primary text-white text-uppercase custom-subtile">
          {projet.libelle_type}
        </Card.Text>
        <Card.Text className="bg-primary text-white text-uppercase custom-subtile">
          {projet.libelle_statut}
        </Card.Text>
      </Card.ImgOverlay>
      <Card.Body>
        <ListGroup variant="flush" className=" ml-n2 mt-n2">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="superficie fw-500">
              {projet.superficie} {projet.libelle_unite}
            </div>
            {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
              <div>
                {projet.nbre_interesse > 0 && (
                  <>
                    <BsHeartFill color="#FF0000" />
                    <span className="px-1 fw-500">
                      {projet.nbre_interesse || 0}
                    </span>{" "}
                    {t("Interested")}
                  </>
                )}
              </div>
            )}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div>
              {" "}
              <FaMapMarkerAlt size={15} /> {projet.localite}
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="fw-500">{projet.details.nom}</div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item text-truncate"
          >
            <div className="text-truncate">{t("Minimum investment")} :</div>
            <span className="fw-500">{Currency(projet.invest_min)}</span>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div> {t("Return on Investment")} :</div>
            <span className="fw-500">{projet.roi}%</span>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div> {t("Estimated duration")} :</div>
            <span className="fw-500">{projet.duree} {projet.libelle_typeduree} </span>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="custom-list-group-item">
            <ProgressBar
              now={projet.pourcentage_mont_invest}
              label={`${projet.pourcentage_mont_invest}% Financé`}
              min={
                projet.pourcentage_mont_invest > 0 &&
                  projet.pourcentage_mont_invest < 20
                  ? 20
                  : 0
              }
            />
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="custom-card-footer">
        <Card.Link
          href={route(projet.code)}
          className="text-decoration-none stretched-link text-center text-white"
        >
          {t("Project details")}
        </Card.Link>
      </Card.Footer>
    </Card>
  );
}
