import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useQuery } from '@apollo/client';
import { GET_LANGUES_BY_CODE, GET_SLIDER_PROJECTS } from "./queries";
import ListItems from './ListItems';

function App({ type }) {

  const [projets, setProjets] = useState([])
  const [lngCode, setLngCode] = useState('fr')
  const [langues] = useState(require('./local.json'))
  const allType = ["LOTISSEMENT", "VEFA", "LOCATIF", "ACHAT-REVENTE"];

  useQuery(GET_LANGUES_BY_CODE, {
    variables: { code: localStorage.getItem('current_lng') || 'fr' },
    onCompleted: (data) => {
      localStorage.setItem('langueId', data.langueByCode.id);
      setLngCode(data.langueByCode.code)
    },
    fetchPolicy: "no-cache",
  });



  const { loading, error } = useQuery(GET_SLIDER_PROJECTS, {
    variables: {
      status: ['CANCELED', 'FULL_INVESTMENT'],
      types: type ? [type] : allType,
      langueId: parseInt(localStorage.getItem('langueId'))
    },
    onCompleted: data => setProjets(data.projets),
    fetchPolicy: "no-cache",
  });


  function t(translation) {
    return langues[lngCode][translation]
  }


  // Define a custom comparator function
  function sortByStatus(a, b) {
    const statusOrder = {
      'OPEN_FOR_INVESTMENT': 0,
      'in-VERIF_IN_PROGRESS': 1,
      'VOTING': 2
    };

    return statusOrder[a.code_statut] - statusOrder[b.code_statut];
  }

  // Sort the array by status using the custom comparator function
  projets.sort(sortByStatus);



  const items = projets.length < 3 ? projets.length : 3
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: items,
    slidesToScroll: items,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  if (loading) return <p className="text-center my-auto"> {t('Laoding')}</p>;
  if (error) return `Error! ${error.message}`;

  return (

    <div className="container-fluid mx-auto">
      {projets.length > 0 ?
        <Slider {...settings} className="overflow-hidden">
          {projets.map((project) => (
            <div className='item' key={project.id}>
              <ListItems
                projet={project}
                langues={langues}
                lngCode={lngCode}
              />
            </div>
          ))}
        </Slider>
        : <div className="text-center"> {t('No property available at the moment')} </div>

      }
    </div>


  )
}

export default App;