
const OPENFORINVESTMENT = "OPEN_FOR_INVESTMENT";
const VOTING = "VOTING";
const VERIFICATIONINPROGRESS = "VERIF_IN_PROGRESS";
const FULLINVESTMENT = "FULL_INVESTMENT";
const CANCELED = "CANCELED";







export {
    OPENFORINVESTMENT,
    VOTING,
    VERIFICATIONINPROGRESS,
    FULLINVESTMENT,
    CANCELED
}