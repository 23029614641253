import { gql } from '@apollo/client';

const GET_LANGUES_BY_CODE = gql`
  query getLangue($code: Mixed!) {
    langueByCode(where: { column: "code", value: $code }) {
      id
      code
    }
  }
`;

const GET_SLIDER_PROJECTS = gql`
  query FetchSliderProjectData($status: Mixed!, $types: Mixed, $langueId: Int!) {
    projets(
      hasStatut: { column: CODE, operator: NOT_IN, value: $status } 
      hasType: {column: CODE, operator: IN,  value: $types }
      where: { column: IS_PUBLISHED, operator: EQ, value: 1 }
    ) {
      id
      nom
      code
      code_statut
      code_type
      libelle_unite
      libelle_titre(langue_id: $langueId)
      libelle_statut(langue_id: $langueId)
      libelle_type(langue_id: $langueId)
      libelle_type_unite(langue_id: $langueId)
      libelle_typeduree(langue_id: $langueId)
      typebien(langue_id: $langueId)
      libelle_typebien(langue_id: $langueId)
      libelle_titre_obtenir(langue_id: $langueId)
      
      details(langue_id: $langueId) {
        nom
        description
        note
        acces
        avec_bail
        avec_tf
        comp_terrains
        nature_sol
        budget_previsionnel
        planning_previsionnel
        equipement
        aproximite
        raisoninvestir
        garanties
      }
      superficie
      cout
      duree
      photo
      invest_min
      roi
      nbre_interesse
      montant_invest
      pourcentage_mont_invest
      lat
      long
      localite
      lieu_localite
      created_at
      lieu {
        id
        localite
        created_at
        updated_at
      }
    }
  }
`;

const GET_PROJECTS_BY_TYPE = gql`
  query projectsByType($type: Mixed!, $langueId: Int!) {
    projetsPerType(
      withTypes: { column: CODE, operator: EQ, value: $type } 
      where: { column: IS_PUBLISHED, operator: EQ, value: 1 }
    ) {
      id
      nom
      code
      code_statut
      code_type
      libelle_unite
      libelle_titre(langue_id: $langueId)
      libelle_statut(langue_id: $langueId)
      libelle_type(langue_id: $langueId)
      libelle_type_unite(langue_id: $langueId)
      libelle_typeduree(langue_id: $langueId)
      typebien(langue_id: $langueId)
      libelle_typebien(langue_id: $langueId)
      libelle_titre_obtenir(langue_id: $langueId)
      
      details(langue_id: $langueId) {
        nom
        description
        note
        acces
        avec_bail
        avec_tf
        comp_terrains
        nature_sol
        budget_previsionnel
        planning_previsionnel
        equipement
        aproximite
        raisoninvestir
        garanties
      }
      superficie
      cout
      duree
      photo
      invest_min
      roi
      nbre_interesse
      montant_invest
      pourcentage_mont_invest
      lat
      long
      localite
      lieu_localite
      created_at
      lieu {
        id
        localite
        created_at
        updated_at
      }
    }
  }
`;
const GET_PARAMS = gql`
  query getParams($type: Mixed) {
    parametres(where: { column: TYPE, operator: EQ, value: $type }) {
      id
      libelle
      code
      type
    }
  }
`;


export { GET_LANGUES_BY_CODE, GET_SLIDER_PROJECTS, GET_PROJECTS_BY_TYPE, GET_PARAMS }