import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import APP_ENV from "./utils/Env";


const client = new ApolloClient({
  uri: APP_ENV.baseURL_graphql,
  cache: new InMemoryCache(),
});

const sliderId = document.getElementById('reise-slider-project')
if (sliderId) {

  const root = ReactDOM.createRoot(sliderId);
  const type = JSON.parse(sliderId.getAttribute( 'data-default' ))?.type

  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App type={type} />
      </ApolloProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

