import APP_ENV from "./Env";

const Currency = (money) => {
  if (money) {
    if (!Number.isInteger(money)) {
      money = parseInt(money.replaceAll(" ", ""));
    }

    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "XOF",
    }).format(money);
  }

  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XOF",
  }).format(0);
};

const asset = (path) => {
  if (path && APP_ENV.asset_url !== APP_ENV.baseURL) {
    return `${APP_ENV.asset_url}/${path}`;
  } else if (path) {
    return `${APP_ENV.baseURL}/${path.replace("public", "storage")}`;
  }

  return `${APP_ENV.asset_static_url}/logo.png`;
};

const staticAsset = (fileName) => `${APP_ENV.asset_static_url}/${fileName}`;

const route = (project_code) => `${APP_ENV.invest_url}/projets/${project_code}`;

export { Currency, asset, staticAsset, route };
